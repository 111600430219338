import axios from 'axios';
import config from "../configs/clientConfig";
import { v4 as uuidv4 } from 'uuid';
import { notifications } from '@mantine/notifications';
import { ClaimActions, CommonApiEntities, ProgressReportFrequencies } from '../models/enums';

const successNotificationColor = 'teal';
const errorNotificationColor = 'red';
const defaultNotificationColor = '#005eff'

// stupid brigade hardcoding
export async function uploadImage(file: File) {
    try {
        let form = new FormData();
        form.append('uploadType', 'image');
        form.append('fileName', `brigade/${file.name.replace(/[\s@]+/g, "_")}`);
        form.append('file', file);
        const res = await axios.post(config.TransactionServerUrl + 'files/upload',
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        await new Promise((res, _) => setTimeout(() => { res(null); }, 1000));
        return { ...res.data, fileName: res.data.fileName.replace("brigade/", "") };
        // return { ...res.data, fileName: res.data.fileName };
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function updateForm(payload: { [K: string]: any; }) {
    try {
        const res = await axios.post(config.TransactionServerUrl + 'portal/updateFormData', { ...payload });
        console.log(res.data);
        return res.data
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function getStartProcessDefinitionKeys({ user, customer, project }: { user: string; customer: string; project: string }) {
    try {
        const res = await axios.get(config.TransactionServerUrl + `process-engine/process/definition/list/${customer}/${user}/${project}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

// export async function getAssignedTasks({ customer, userId }: { customer: string; userId: string }) {
//     try {
//         const res = await axios.get(`${config.TransactionServerUrl}process-engine/task/list/assignee/${customer}/${userId}`);
//         console.log(res.data);
//         return res.data;
//     }
//     catch (err) {
//         console.error(`Error response: ${err}`);
//         throw err;
//     }
// }

// export async function getInitiatedTasks({ customer, userId }: { customer: string; userId: string; }) {
//     try {
//         const res = await axios.get(`${config.TransactionServerUrl}process-engine/task/list/initiator/${customer}/${userId}`);
//         console.log(res.data);
//         return res.data;
//     }
//     catch (err) {
//         console.error(`Error response: ${err}`);
//         throw err;
//     }
// }

// TODO: Formalize later into different modules.
export async function startProcessByDefinitionKey({ processDefinitionKey: typeId, processDetails, ...rest }: { userId: string; tenant: string; processDefinitionKey: string; processDetails: { [K: string]: any; }; projectId: string; processStartForm?: { [K: string]: any; } }) {
    try {
        // incidentModuleMainForm: null, processStartForm: null
        // requestModuleMainForm: processDetails,
        const res = await axios.post(config.TransactionServerUrl + 'process-engine/process/start', { typeId, requestModuleMainForm: processDetails, ...rest });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function saveFormByUserAndTask({ customer, userId, taskId, projectId, payload, moduleId }: { projectId: string; customer: string; userId: string; taskId: string; payload: { [K: string]: any; }; moduleId: string; }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}process-engine/form/save/${customer}/${moduleId}/${userId}/${projectId}/${taskId}`, { ...payload });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function submitForm({ customer, userId, taskId, outcome, payload, moduleId, ticketId }: { customer: string; userId: string; taskId: string; payload: { [K: string]: any; }; outcome?: string; property?: string; moduleId: string; ticketId: string; }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}process-engine/task/submit`, {
            taskId,
            userId,
            moduleId,
            ticketId,
            tenant: customer,
            outcome: outcome || "",
            // propertyId: property,
            formInput: { ...payload }
        });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function getTaskDetailByTaskId({ customer, taskId }: { customer: string; taskId: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/task/${customer}/${taskId}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function listCommentsForProcess({ processInstanceId }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/comment/${processInstanceId}/list`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}


export async function submitComment({ processInstanceId, userId, commentType, commentMessage, customerId, projectId, moduleId }) {
    try {
        const createdAt = Date.now();
        const res = await axios.post(`${config.TransactionServerUrl}process-engine/comment/add/${customerId}`, {
            ticketId: processInstanceId, userId, commentType, commentMessage, projectId, moduleId
        });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function addNotificationToken({ customerId, user, token }) {
    try {
        let form = new FormData();
        form.append('customerName', customerId);
        form.append('userID', user);
        form.append('token', token);
        const res = await axios.post(config.TransactionServerUrl + 'common/apis/gcm/token/save',
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        console.log(res.data);
        return res.data
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        // throw err;
    }
}

export async function removeNotificationToken({ customerId, user, token }) {
    try {
        let form = new FormData();
        form.append('customerName', customerId);
        form.append('userID', user);
        form.append('token', token);
        const res = await axios.delete(config.TransactionServerUrl + 'common/apis/gcm/token/remove',
            {
                headers: { "Content-Type": "multipart/form-data" },
                data: form
            }
        );
        console.log(res.data);
        return res.data
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        // throw err;
    }
}

export async function getStartForm({ customerId, processDefKey }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/process/start/form/${customerId}/${processDefKey}`);
        console.log(res.data);
        return res.data
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function uploadFileV2({ file, isDraft, fileId }: { file: File, isDraft?: boolean, fileId?: string }) {
    try {
        let form = new FormData();
        form.append('file', file);
        form.append('isDraft', `${isDraft || true}`);
        if (fileId) { form.append('fileId', `${fileId}`); } //adding this as it is required for storing new versions of file
        const res = await axios.post(config.TransactionServerUrl + 'files/upload/v2',
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        await new Promise((res, _) => setTimeout(() => { res(null); }, 1000));
        notifications.show({ title: 'Uploaded!', message: 'File uploaded successfully.' })
        return res.data.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        const { errorMessage } = err.response.data
        notifications.show({ title: 'Failed!', message: errorMessage || 'File could not be uploaded.', color: errorNotificationColor })
        throw err;
    }
}

export async function downloadFile({ fileId, version, fileName }: { fileId: string; version?: number; fileName: string; }) {
    try {
        const res = await axios.get(version !== undefined ? `${config.TransactionServerUrl}files/download/v2?fileId=${fileId}&versionId=${version}` : `${config.TransactionServerUrl}files/download/v2?fileId=${fileId}`, {
            responseType: 'blob'
        });
        // create file link in browser's memory
        const href = URL.createObjectURL(res.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}
export async function deleteImage({ }) {
}

export async function getFileHistory({ fileId }: { fileId: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}files/history?fileId=${fileId}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

/**API to fetch dynamic drop down values from backend */
export async function getDropdownOption({ customerId, variableType, projectId }: { customerId: string, variableType: string | null; projectId: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/reference/variable/${customerId}/${projectId}/${variableType}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

/**API to update core fields data in ticket.requests */
export async function updateCoreFields({ ticketId, userId, moduleId, tenant, requestModuleMainForm }: { ticketId: string, userId: string, moduleId: string, tenant: string, requestModuleMainForm: { dueDate?: string, priority?: string, title?: string, description?: string } }) {
    const data = { ticketId, userId, moduleId, tenant, requestModuleMainForm }
    try {
        const res = await axios.put(`${config.TransactionServerUrl}process-engine/process/fields/update`,
            data,
            { headers: { "Content-Type": "application/json" } });
        console.log(res.data);
        return res.data;
    } catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

// API to get general form for a ticket
export async function getGeneralForm({ customerId, ticketId, formKey }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/custom/form/${customerId}/${ticketId}/${formKey}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}


export async function getFeatureConfig({ payload, tenant, feature }: { tenant: string; feature: string; payload: { [K: string]: any; } }) {
    try {
        // incidentModuleMainForm: null, processStartForm: null
        // requestModuleMainForm: processDetails,
        const res = await axios.post(config.TransactionServerUrl + `feature/config/${tenant}/${feature}`, { ...payload });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function updateAssignee({ ...payload }: { tenant: string; moduleId: string; ticketId: string; taskId: string; oldAssignee: string; newAssignee: string; userId: string; }) {
    try {
        const res = await axios.post(config.TransactionServerUrl + `process-engine/task/update`, { ...payload });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}


export async function createGeneralAttachment({ ...payload }: { tenant: string; moduleId: string; ticketId: string; fileDetails: any[] }) {
    console.log('ran')
}

export async function submitAttachmentComment({ processInstanceId, userId, commentType, commentMessage, customerId, projectId, moduleId, attachments }) {
    try {
        const createdAt = Date.now();
        const res = await axios.post(`${config.TransactionServerUrl}process-engine/comment/add/${customerId}`, {
            ticketId: processInstanceId, userId, commentType, commentMessage, projectId, moduleId, attachments
        });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function getTickets({ tenant, moduleId, ticketId, projectId }: { tenant: string; moduleId: string; ticketId?: string; projectId: string; }) {
    try {
        const res = await axios.get(ticketId ? `${config.TransactionServerUrl}process-engine/ticket/${tenant}/${projectId}/${moduleId}/${ticketId}` : `${config.TransactionServerUrl}process-engine/ticket/list/${tenant}/${projectId}/${moduleId}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
};

export async function getHistory({ tenant, ticketId }: { tenant: string; ticketId: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/history/list/${tenant}/${ticketId}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function getNovuSubscription({ customerId, userId }: { customerId: string; userId: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}novu/apis/subscription/keys?customerID=${customerId}&userID=${userId}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }

}

export async function getCompletedTasksByTicket({ tenant, ticketId }: { tenant: string; ticketId: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/task/completed/${tenant}/${ticketId}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

// Tag Requests
export interface ITagRequestPayload {
    ticketId?: string;
    userId: string;
    moduleId: string;
    tagId: string;
    newTagId?: string;
};


export async function listTags({ tenant }: { tenant: string; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}process-engine/tag/list/${tenant}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function renameTag({ tenant, payload }: { tenant: string; payload: ITagRequestPayload; }) {
    try {
        const res = await axios.put(`${config.TransactionServerUrl}process-engine/tag/rename/${tenant}`, { ...payload });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}
export async function addTag({ tenant, payload }: { tenant: string; payload: ITagRequestPayload; }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}process-engine/tag/add/${tenant}`, { ...payload });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function removeTag({ tenant, payload }: { tenant: string; payload: ITagRequestPayload; }) {
    try {
        const res = await axios.delete(`${config.TransactionServerUrl}process-engine/tag/remove/${tenant}`, { data: { ...payload } });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export interface IFeatureConfigApiPayload {
    projectIdList?: string[];
    moduleIdList?: string[];
    ticketDefinitionList?: string[];
    featureName?: string;
};

export async function getFeatureConfig2({ payload, tenant, feature }: { tenant: string; feature?: string; payload: IFeatureConfigApiPayload }) {
    try {
        // incidentModuleMainForm: null, processStartForm: null
        // requestModuleMainForm: processDetails,
        const res = await axios.post(config.TransactionServerUrl + `feature/config/list/${tenant}`, feature ? { ...payload, featureName: feature } : { ...payload });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export interface IWatcherRequest {
    op: 'add' | 'remove';
    tenant: string;
    ticketId: string;
    userId: string;
    moduleId: string;
    watcherId: string;
};
export async function updateWatcherForTicket({ op, tenant, ...rest }) {
    try {
        let res;
        if (op === 'add') {
            res = await axios.post(`${config.TransactionServerUrl}process-engine/watcher/${op}/${tenant}`, { ...rest });
        }
        else {
            res = await axios.delete(`${config.TransactionServerUrl}process-engine/watcher/${op}/${tenant}`, { data: rest });
        }
        // const res = await axios.post(`${config.TransactionServerUrl}process-engine/watcher/${op}/${tenant}`, { ...rest });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function checkPermission({ customerId, moduleId, projectId, ...rest }: { objectType: string; objectId: string; subjectType: string; subjectId: string; permission: string; projectId: string; moduleId: string; customerId: string; }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}permissions/check-permission`,
            {
                customerId,
                moduleId,
                projectId,
                request: { ...rest }
            });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

export async function deleteTicket({ tenant, ticketId, moduleId, isAdhoc }: { tenant: string; ticketId: string; moduleId: string; isAdhoc: boolean; }) {
    try {
        const res = await axios.delete(`${config.TransactionServerUrl}process-engine/process/delete/${tenant}/${moduleId}/${ticketId}?isAdhoc=${isAdhoc}`);
        console.log(res.data);
        notifications.show({ title: 'Success!', message: 'Ticket deleted succesfully.', color: successNotificationColor })
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        const { errorMessage } = err.response.data
        notifications.show({ title: 'Failed!', message: errorMessage || 'Ticket could not be deleted', color: errorNotificationColor })
        throw err;
    }
}

export async function listPermissionedResources({ customerId, projectId, moduleId, ...rest }: { resourceType: string; subjectType: string; subjectId: string; permission: string; customerId: string; projectId: string; moduleId: string; }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}permissions/lookup-resources`,
            {
                customerId,
                projectId,
                moduleId,
                request: { ...rest }
            });
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

// TODO: eventually make entity an enum to limit the possibilities
export async function commonFetch({ projectId, entity, filters }: { projectId: string; entity: CommonApiEntities; filters?: { [K: string]: any; }; }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}common/apis/${entity}?projectId=${projectId}${filters ? `&${Object.keys(filters).map(k => `${k}=${filters[k]}`).join("&")}` : ""}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}
/*
  Sample form data:
  { 'customer_id': 'myhome',
  'userId': 'MHT_CHK_002',
  'opAction': 'create_unit',
  'description': 'Jai Hind',
  'blockId': 'BLK_E',
  'unitType': 'apt',
  'title': 'E-001',
  'unitId': 'apt_BLK_E_U02'}
*/
export async function createUnit({ customerId, id, ...rest }: { customerId: string; userId: string; description: string; blockId: string; unitType: string; title: string; floorIdx: number; id?: string; }) {
    try {
        let form = new FormData();
        if (id) {
            form.append('unitId', id);
        }
        else {
            form.append('unitId', uuidv4());
        }
        form.append('opAction', 'create_unit');
        form.append("customer_id", customerId);
        Object.keys(rest).forEach(k => {
            form.append(k, rest[k]);
        });
        const res = await axios.post(config.TransactionServerUrl + 'oplog/createUnit',
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        notifications.show({ title: 'Failed!', message: err?.errorMessage || 'Unit could not be created', color: errorNotificationColor })
        throw err;
    }
}

export async function createUnitActivity({ customerId, ...rest }: { customerId: string; userId: string; propertyId: string; unitId: string, activityTypeId: string, unitActivityId?: string, titleSuffix?: string, isDPR: boolean, plannedTotalQuantity: number | undefined | null, planStart?: string | undefined | null, planEnd?: string | undefined | null }) {
    try {
        let form = new FormData();
        if (!rest.unitActivityId) {
            form.append('unitActivityId', uuidv4());
        }
        form.append('opAction', 'create_unit_activity');
        form.append("customer_id", customerId);
        Object.keys(rest).forEach(k => {
            if (rest[k]) { form.append(k, rest[k]); }
        });
        const res = await axios.post(config.TransactionServerUrl + 'oplog/createUnitActivity',
            form,
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        // console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        notifications.show({ title: 'Failed!', message: err?.errorMessage || 'Unit activity could not be created!', color: errorNotificationColor })
        throw err;
    }
}

export async function deleteFile({ fileId, versionId }: { fileId: string, versionId?: string | number }) {
    try {
        let form = new FormData();
        form.append('fileId', `${fileId}`);
        // form.append('isDraft', `${isDraft || true}`);
        if (versionId) { form.append('versionId', `${versionId}`); } //adding this as it is required for storing new versions of file
        const res = await axios.delete(config.TransactionServerUrl + 'files/delete', {
            data: form,
            headers: { "Content-Type": "multipart/form-data" }
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        notifications.show({ title: 'Deleted!', message: 'File deleted successfully.' });
        return res.data.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        const { errorMessage } = err.response.data
        notifications.show({ title: 'Failed!', message: errorMessage || 'File could not be deleted.', color: errorNotificationColor })
        throw err;
    }
}

/** Wrapper API for ticket list that consumes multiple project, userId and ticketType */
export async function getTicketsForMultipleProjects({ tenant, projectIds, userId, ticketType }: { tenant: string; projectIds: string[]; userId?: string, ticketType?: string }) {
    try {
        let url = `${config.TransactionServerUrl}process-engine/ticket/list/multiple-projects/${encodeURIComponent(tenant)}/requests?projectIds=${encodeURIComponent(projectIds.join(','))}`;
        if (userId && ticketType) url += `&userId=${encodeURIComponent(userId)}&ticketType=${encodeURIComponent(ticketType)}`;
        else if (userId) url += `&userId=${encodeURIComponent(userId)}`;
        else if (ticketType) url += `&ticketType=${encodeURIComponent(ticketType)}`;

        // const res = await axios.get(url, { headers: { 'Authorization' : 'Basic FBT_TX_SERVER_KOTLIN'}});
        const res = await axios.get(url);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
};

/** API to rename a file */
export const renameFile = async ({ fileId, fileName }: { fileId: string, fileName: string }) => {
    try {
        let url = `${config.TransactionServerUrl}/files/rename`;
        const data = { fileId, fileName }
        const res = await axios.put(url, data, { headers: { "Content-Type": "application/json" } });
        return res.data;
    } catch (error) {
        console.error(`Error response from fileRename: ${error}`);
        throw error;
    }
}

export async function fetchWBSActivities({ projectId, unitActivityIds, frequency, targetPeriod }: { projectId: string; unitActivityIds?: string[]; frequency: string, targetPeriod?: string }) {
    try {
        const res = await axios.get(`${config.TransactionServerUrl}dpr/apis/wbs?projectId=${projectId}&frequency=${frequency}${unitActivityIds?.length ? `&unitActivityIds=${unitActivityIds}` : ''}${targetPeriod?.length ? `&targetPeriod=${targetPeriod}` : ''}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }
}

//To upsert WBS targets
export async function upsertTargets({ projectId, scheduleList }: { projectId: string, scheduleList: { unitActivityId: string, frequency: string, targetPeriod: string, quantity: number }[] }) {
    try {
        const res = await axios.post(config.TransactionServerUrl + 'dpr/apis/wbs',
            { projectId, scheduleList },
            { headers: { "Content-Type": "application/json" } }
        );
        notifications.show({ title: 'Success', message: 'Targets saved successfully!', color: successNotificationColor })
        // console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        notifications.show({ title: 'Failed!', message: err?.errorMessage || 'Unit activity could not be created!', color: errorNotificationColor })
        throw err;
    }
}

export async function getDPRReport({ projectId, targetPeriod, frequency }: { projectId: string; targetPeriod: string; frequency?: ProgressReportFrequencies }) {
    // /dpr/apis/reports?projectId=mang&targetPeriod=2024-11-11&frequency=DAY
    try {
        const res = await axios.get(`${config.TransactionServerUrl}dpr/apis/reports?projectId=${projectId}&targetPeriod=${targetPeriod}${frequency ? `&frequency=${frequency}` : ""}`);
        console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        throw err;
    }

}

export async function claimOrUnclaimTask({ projectId, ticketId, moduleId, taskId, tenant, userId, action }: { action: ClaimActions; projectId: string; ticketId: string; moduleId: string; taskId: string; userId: string; tenant: string; }) {
    try {
        const res = await axios.post(`${config.TransactionServerUrl}process-engine/task/${action === ClaimActions.CLAIM ? "claim" : "unclaim"}`,
            { projectId, ticketId, moduleId, taskId, tenant, userId },
            { headers: { "Content-Type": "application/json" } }
        );
        console.log(res.data);
        notifications.show({ title: 'Success', message: `Successfully ${action === ClaimActions.CLAIM ? "Claimed" : "Unclaimed"} Task!`, color: successNotificationColor })
        // console.log(res.data);
        return res.data;
    }
    catch (err) {
        console.error(`Error response: ${err}`);
        notifications.show({ title: 'Failed!', message: err?.errorMessage || `Task could not be ${action === ClaimActions.CLAIM ? "claimed" : "unclaimed"}!`, color: errorNotificationColor })
        throw err;
    }
}
