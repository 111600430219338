import React, { useEffect, useRef, useState } from 'react';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { observer } from 'mobx-react-lite';
import { IRequest } from '../../../models/admin/AdminModule';
import { useStore } from '../../../models/ProvideModel';
import { useHistory, useLocation } from 'react-router';
import { IconBrandTelegram, IconApi, IconNotebook, IconLayout2 } from '@tabler/icons-react'

const APIAccordion = ({ groupId,  apiData, expanded } : { groupId : string, apiData : IRequest[], expanded?: boolean }) => {
  const store = useStore();
  const { search, pathname } = useLocation();
  const { push } = useHistory();
  const path = useRef<{ search: URLSearchParams; }>({
    search: new URLSearchParams(search),
  });
  const [expandedPanel, setExpandedPanel] = useState<string | false>(false);
  const apiId = new URLSearchParams(search).get('apiId');

  useEffect(() => {
    if (apiId && apiData.some(item => item.id === apiId)) {
      setExpandedPanel(groupId);
      store.adminModule.setCurrentRequest(apiId);
    }
  }, [apiId, apiData, groupId, store.adminModule]);
  

  const handleItemClick = (itemId: string) => {
    store.adminModule.setCurrentRequest(itemId);
    push(`${pathname}?apiId=${itemId}`);
  };

  const handleChange = (panel: string) => (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpandedPanel(isExpanded ? panel : false);
  };

  return (
    <div style={{ margin: '0.5em 0.9em', }}>
        <ExpansionPanel key={groupId} expanded={expandedPanel === groupId} onChange={handleChange(groupId)}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon color='primary'/>}
            aria-controls={`${groupId}-content`}
            id={`${groupId}-header`}
          >
            <Typography style={{ fontSize: '1em' , fontWeight: 'bold' }}>{apiData[0]?.groupTitle}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails style={{ display: 'flex', flexDirection: 'column'}}>
            {apiData && (apiData).map((item) => {return (
              <div key={item.id} 
              style={{ display: 'flex', padding: '0.5em', cursor: 'pointer', margin: '0.25em 0em', fontWeight: item.id === store.adminModule.currentRequest?.id ? 600 : 400, background: item.id === store.adminModule.currentRequest?.id ? 'blanchedalmond' : 'none' }}
              onClick={() =>handleItemClick(item.id)}>
                <span style={{ display: 'flex', alignItems: 'center', marginRight: '0.3em' }}>{item.meta && item.meta.get('source') === 'APP_SMITH' ? <IconBrandTelegram width={'1.25em'} height={'1.25em'} color='#e15615' /> : item.meta && item.meta.get('source') === 'RETOOL' ? <IconLayout2 width={'1.25em'} height={'1.25em'} color='#e15615' /> : item.meta && item.meta.get('source') === 'DOCS' ? <IconNotebook width={'1.25em'} height={'1.25em'} color='brown'/> : <IconApi width={'1.25em'} height={'1.25em'} color={item.method === 'GET' ? 'green' : item.method === 'POST' ? 'orange' : item.method === 'DELETE' ? 'red' : '#3779c6'}/>}</span><span style={{ fontSize: '0.975em' }}>{item.title}</span>
              </div>
             )})}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      {/* ))} */}
    </div>
);
}

export default observer(APIAccordion);
